// Generated by ReScript, PLEASE EDIT WITH CARE

import * as A from "../../../styleguide/components/Link/A.res.js";
import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Money from "../../../libs/Money.res.js";
import * as React from "react";
import * as $$String from "rescript/lib/es6/string.js";
import * as Control from "../../../styleguide/components/Control/Control.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Cloudinary from "../../../libs/Cloudinary.res.js";
import * as IconSelect from "../../../styleguide/icons/IconSelect.res.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import Format from "date-fns/format";
import * as IconSelectRevert from "../../../styleguide/icons/IconSelectRevert.res.js";
import * as UserOrdersScss from "./UserOrders.scss";
import * as JsxRuntime from "react/jsx-runtime";

var css = UserOrdersScss;

function buildLogoUrl(src) {
  return Cloudinary.Url.build("Png", {
              hd: {
                TAG: "Width",
                _0: 320
              },
              tl: /* [] */0
            }, src);
}

var Helpers = {
  buildLogoUrl: buildLogoUrl
};

var initialState = {
  isShowMoreExpanded: false
};

function reducer(state, action) {
  return {
          isShowMoreExpanded: !state.isShowMoreExpanded
        };
}

function Order(props) {
  var order = props.order;
  var mobile = props.mobile;
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var state = match[0];
  var x = order.orders.length;
  var match$1 = state.isShowMoreExpanded;
  return JsxRuntime.jsx(JsxRuntime.Fragment, {
              children: Caml_option.some(JsxRuntime.jsxs("div", {
                        children: [
                          JsxRuntime.jsxs("div", {
                                children: [
                                  JsxRuntime.jsx("div", {
                                        children: JsxRuntime.jsx("img", {
                                              alt: "Logo",
                                              src: buildLogoUrl(order.logo)
                                            }),
                                        className: css.logo
                                      }),
                                  JsxRuntime.jsx("div", {
                                        children: order.name,
                                        className: css.title
                                      }),
                                  JsxRuntime.jsx("div", {
                                        children: x !== 1 ? String(x) + " orders" : "1 order",
                                        className: css.order
                                      }),
                                  JsxRuntime.jsx("div", {
                                        children: JsxRuntime.jsx(Control.make, {
                                              onClick: (function (param) {
                                                  dispatch("ToggleShowMore");
                                                }),
                                              children: state.isShowMoreExpanded ? JsxRuntime.jsx(IconSelectRevert.make, {
                                                      size: "MD",
                                                      color: "LighterGray"
                                                    }) : JsxRuntime.jsx(IconSelect.make, {
                                                      size: "MD",
                                                      color: "LighterGray"
                                                    })
                                            }),
                                        className: css.arrow
                                      })
                                ],
                                className: css.userPanelHeader
                              }),
                          match$1 ? JsxRuntime.jsx("div", {
                                  children: JsxRuntime.jsxs("table", {
                                        children: [
                                          mobile ? null : JsxRuntime.jsx("thead", {
                                                  children: JsxRuntime.jsxs("tr", {
                                                        children: [
                                                          JsxRuntime.jsx("td", {
                                                                children: "Product"
                                                              }),
                                                          JsxRuntime.jsx("td", {
                                                                children: "Quantity"
                                                              }),
                                                          JsxRuntime.jsx("td", {
                                                                children: "Total Price"
                                                              }),
                                                          JsxRuntime.jsx("td", {
                                                                children: "Ordered"
                                                              }),
                                                          JsxRuntime.jsx("td", {
                                                                children: "Status"
                                                              })
                                                        ]
                                                      })
                                                }),
                                          JsxRuntime.jsx("tbody", {
                                                children: Belt_Array.mapWithIndex(order.orders, (function (idx, order) {
                                                        var match = order.status;
                                                        var tmp;
                                                        switch (match) {
                                                          case "InProgress" :
                                                              tmp = "In Progress";
                                                              break;
                                                          case "Finalized" :
                                                              tmp = "Finalized";
                                                              break;
                                                          case "Cancelled" :
                                                              tmp = "Cancelled";
                                                              break;
                                                          
                                                        }
                                                        var match$1 = order.status;
                                                        var tmp$1;
                                                        switch (match$1) {
                                                          case "InProgress" :
                                                              tmp$1 = css.inProgress;
                                                              break;
                                                          case "Finalized" :
                                                              tmp$1 = css.finalized;
                                                              break;
                                                          case "Cancelled" :
                                                              tmp$1 = css.cancelled;
                                                              break;
                                                          
                                                        }
                                                        return JsxRuntime.jsxs("tr", {
                                                                    children: [
                                                                      JsxRuntime.jsxs("td", {
                                                                            children: [
                                                                              JsxRuntime.jsx("div", {
                                                                                    children: $$String.capitalize_ascii(order.category),
                                                                                    className: css.subTitle
                                                                                  }),
                                                                              JsxRuntime.jsx(A.make, {
                                                                                    href: order.url,
                                                                                    targetBlank: true,
                                                                                    className: css.title,
                                                                                    children: $$String.capitalize_ascii(order.name)
                                                                                  })
                                                                            ]
                                                                          }),
                                                                      JsxRuntime.jsxs("td", {
                                                                            children: [
                                                                              mobile ? JsxRuntime.jsx("span", {
                                                                                      children: "Quantity : ",
                                                                                      className: css.tdTitle
                                                                                    }) : null,
                                                                              String(order.quantity)
                                                                            ]
                                                                          }),
                                                                      JsxRuntime.jsxs("td", {
                                                                            children: [
                                                                              mobile ? "Total Price : " : null,
                                                                              Money.formatWithCents(order.pricePerMonth),
                                                                              "/month"
                                                                            ]
                                                                          }),
                                                                      JsxRuntime.jsxs("td", {
                                                                            children: [
                                                                              mobile ? "Ordered : " : null,
                                                                              Format(order.orderedAt, "MM/dd/yyyy")
                                                                            ]
                                                                          }),
                                                                      JsxRuntime.jsx("td", {
                                                                            children: JsxRuntime.jsx("div", {
                                                                                  children: tmp,
                                                                                  className: Cx.cx([
                                                                                        css.tag,
                                                                                        tmp$1
                                                                                      ])
                                                                                })
                                                                          })
                                                                    ]
                                                                  }, String(idx));
                                                      }))
                                              })
                                        ],
                                        className: css.orderTable
                                      }),
                                  className: css.userPanelBody
                                }) : null
                        ],
                        className: css.userPanelItem
                      }))
            });
}

var make = Order;

export {
  css ,
  Helpers ,
  initialState ,
  reducer ,
  make ,
}
/* css Not a pure module */
