// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../../libs/ID.res.js";
import * as Api from "../../../api/Api.res.js";
import * as Hooks from "../../../libs/Hooks.res.js";
import * as Order from "./Order.res.js";
import * as React from "react";
import * as $$Promise from "../../../bindings/Promise.res.js";
import * as IconInfo from "../../../styleguide/icons/IconInfo.res.js";
import * as TopNavbar from "../top-navbar/TopNavbar.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Pagination from "../../../styleguide/components/Pagination/Pagination.res.js";
import * as SentryLogger from "../../../loggers/SentryLogger.res.js";
import * as UserOrdersScss from "./UserOrders.scss";
import * as JsxRuntime from "react/jsx-runtime";
import * as UserReservationData from "../../../models/UserReservationData.res.js";

var css = UserOrdersScss;

function UserOrders$UserOrders(props) {
  var orders = props.orders;
  var totalReservations = props.totalReservations;
  var totalPages = props.totalPages;
  var perPage = props.perPage;
  var offset = props.offset;
  var currentPage = props.currentPage;
  var mobile = props.mobile;
  var initialState = React.useMemo((function () {
          return {
                  isShowMoreExpanded: false,
                  orders: orders,
                  totalPages: totalPages,
                  totalReservations: totalReservations,
                  offset: offset,
                  perPage: perPage,
                  currentPage: currentPage,
                  fetching: false
                };
        }), []);
  var match = Hooks.useReducer(initialState, (function (state, action) {
          if (typeof action !== "object") {
            if (action === "FetchOrders") {
              return {
                      TAG: "UpdateWithSideEffects",
                      _0: {
                        isShowMoreExpanded: state.isShowMoreExpanded,
                        orders: state.orders,
                        totalPages: state.totalPages,
                        totalReservations: state.totalReservations,
                        offset: state.offset,
                        perPage: state.perPage,
                        currentPage: state.currentPage,
                        fetching: true
                      },
                      _1: (function (param) {
                          var dispatch = param.dispatch;
                          var state = param.state;
                          $$Promise.wait(Api.fetchUserOrders(state.currentPage, state.perPage), (function (x) {
                                  if (x.TAG === "Ok") {
                                    return dispatch({
                                                TAG: "UpdateOrders",
                                                _0: x._0
                                              });
                                  } else {
                                    return SentryLogger.error1({
                                                rootModule: "UserOrders",
                                                subModulePath: {
                                                  hd: "UserOrders",
                                                  tl: /* [] */0
                                                },
                                                value: "make",
                                                fullPath: "UserOrders.UserOrders.make"
                                              }, "UserOrders::FetchOrders::Error", [
                                                "Error",
                                                x._0
                                              ]);
                                  }
                                }));
                        })
                    };
            } else {
              return {
                      TAG: "Update",
                      _0: {
                        isShowMoreExpanded: !state.isShowMoreExpanded,
                        orders: state.orders,
                        totalPages: state.totalPages,
                        totalReservations: state.totalReservations,
                        offset: state.offset,
                        perPage: state.perPage,
                        currentPage: state.currentPage,
                        fetching: state.fetching
                      }
                    };
            }
          }
          if (action.TAG === "Paginate") {
            return {
                    TAG: "UpdateWithSideEffects",
                    _0: {
                      isShowMoreExpanded: state.isShowMoreExpanded,
                      orders: state.orders,
                      totalPages: state.totalPages,
                      totalReservations: state.totalReservations,
                      offset: state.offset,
                      perPage: state.perPage,
                      currentPage: action._0,
                      fetching: state.fetching
                    },
                    _1: (function (param) {
                        param.dispatch("FetchOrders");
                      })
                  };
          }
          var res = action._0;
          return {
                  TAG: "Update",
                  _0: {
                    isShowMoreExpanded: state.isShowMoreExpanded,
                    orders: res.orders,
                    totalPages: res.totalPages,
                    totalReservations: res.totalReservations,
                    offset: res.offset,
                    perPage: state.perPage,
                    currentPage: res.currentPage,
                    fetching: false
                  }
                };
        }));
  var dispatch = match[1];
  var state = match[0];
  var tmp;
  if (state.orders.length !== 0) {
    var tmp$1;
    if (state.totalPages < 2) {
      var match$1 = state.totalReservations;
      tmp$1 = match$1 !== 0 ? (
          match$1 !== 1 ? "Showing all" : "Showing 1"
        ) : "No orders found";
    } else {
      tmp$1 = "Showing " + (String(state.offset + 1 | 0) + (" to " + (String(state.offset + state.orders.length | 0) + (" of " + String(state.totalReservations)))));
    }
    tmp = JsxRuntime.jsxs(JsxRuntime.Fragment, {
          children: [
            JsxRuntime.jsx("div", {
                  children: Belt_Array.map(state.orders, (function (order) {
                          return JsxRuntime.jsx(Order.make, {
                                      mobile: mobile,
                                      order: order
                                    }, ID.toString(order.id));
                        })),
                  className: css.userPanel
                }),
            JsxRuntime.jsxs("div", {
                  children: [
                    state.totalPages > 1 ? JsxRuntime.jsx(Pagination.make, {
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            onPageClick: (function (pageNum) {
                                dispatch({
                                      TAG: "Paginate",
                                      _0: pageNum
                                    });
                              })
                          }) : null,
                    JsxRuntime.jsx("div", {
                          children: tmp$1,
                          className: css.paginationInfo
                        })
                  ],
                  className: css.paginationBar
                })
          ]
        });
  } else {
    tmp = JsxRuntime.jsxs("div", {
          children: [
            JsxRuntime.jsx("span", {
                  children: JsxRuntime.jsx(IconInfo.make, {
                        size: "MD",
                        color: "DarkOrange"
                      }),
                  className: css.icon
                }),
            "No Orders Found"
          ],
          className: css.noRecordCard
        });
  }
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(TopNavbar.make, {
                      selectedCategory: "Orders"
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("h1", {
                              children: "My Orders",
                              className: css.titleMain
                            }),
                        tmp
                      ],
                      className: css.contentWrapper
                    })
              ],
              className: css.container
            });
}

var UserOrders = {
  css: css,
  make: UserOrders$UserOrders
};

function UserOrders$default(props) {
  var props$1 = props.props;
  return JsxRuntime.jsx(UserOrders$UserOrders, {
              mobile: props.context.mobile,
              currentPage: props$1.currentPage,
              offset: props$1.offset,
              perPage: props$1.perPage,
              totalPages: props$1.totalPages,
              totalReservations: props$1.totalReservations,
              orders: Belt_Array.map(props$1.orders, UserReservationData.fromJs)
            });
}

var $$default = UserOrders$default;

export {
  UserOrders ,
  $$default as default,
}
/* css Not a pure module */
